import { formatAppNameText } from "@finbackoffice/fe-core";
import { CpfSignupProvider, useRuntimeConfig } from "@finbackoffice/site-core";
import Link from "next/link";
import { FC, useContext, useEffect, useRef } from "react";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Img from "components/base/img/Img";
import Translate from "components/base/translate/Translate";
import LoginPanel from "components/header/login-panel/LoginPanel";
import LanguageDropdown from "components/header/language-dropdown/LanguageDropdown";
import UserPanel from "components/header/user-panel/UserPanel";
import { HeaderContext } from "components/header/Header";
import ExchangeRatePanel from "components/header/exchange-rate/ExchangeRatePanel";
import HeaderNav from "components/header/nav/HeaderNav";
import { InitialDataContext } from "contexts";
import { LoginModal } from "components/header/LoginModal";
import SignupModal from "components/header/SignupModal";
import KycVerificationModal from "../../../account/verification-kyc/KycVerificationModal";
import styles from "./v1.module.sass";

const V1: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { isUserLoggedIn, loginDefaultView, showExchangeRatePanel } = useContext(HeaderContext);
    const { setSiteHeaderHeight } = useContext(InitialDataContext);
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        if (ref.current?.offsetHeight) {
            setSiteHeaderHeight(ref.current?.offsetHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <header className={styles.header} ref={ref}>
            <div className={styles.headerTop}>
                <Link href="/">
                    <Img
                        source={`${ASSETS_URL}/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/desktop/logo.svg`}
                        alt={`${COMMON_SITE_CONFIGS.appName} logo`}
                        title={`${COMMON_SITE_CONFIGS.appName} logo`}
                        width={0}
                        height={0}
                        style={{ width: "100%", height: "auto" }}
                    />
                </Link>
                <h1>
                    <Translate
                        tid="header_headText"
                        replace={{ app_name: COMMON_SITE_CONFIGS.appName }}
                    />
                </h1>
                {isUserLoggedIn ? (
                    <ErrorBoundary name={UserPanel.name}>
                        <UserPanel />
                    </ErrorBoundary>
                ) : (
                    <>
                        <ErrorBoundary name={LoginPanel?.name}>
                            <LoginPanel />
                        </ErrorBoundary>
                        <CpfSignupProvider>
                            <SignupModal />
                        </CpfSignupProvider>
                        <ErrorBoundary name="LoginForm">
                            <LoginModal defaultView={loginDefaultView} />
                        </ErrorBoundary>
                    </>
                )}
            </div>
            <div className={styles.headerNav}>
                <HeaderNav />
                <div className="site-language-container">
                    {showExchangeRatePanel && (
                        <ErrorBoundary name={ExchangeRatePanel.name}>
                            <ExchangeRatePanel />
                        </ErrorBoundary>
                    )}
                    <ErrorBoundary name={LanguageDropdown.name}>
                        <LanguageDropdown />
                    </ErrorBoundary>
                </div>
            </div>
            <KycVerificationModal />
        </header>
    );
};

export default V1;
